import Link from '@components/ui/link'
import Image from 'next/image'

export const TopBannersMobile = ({ images }) => {
  const imagesArr = images && images.length > 0 ? images : []

  return (
    <div className="flex flex-col sm:flex-row-- xs:gap-y-1 xsp:gap-y-2 justify-center items-center mx-auto mt-[10px] bg-[#fff]">
      <div className="flex-col items-center justify-center max-w-[920px]-- max-h-[516px]-- aspect-[920/516] w-auto h-auto w-full-- font-poppins -mb-3">
        <div className="max-[920px]:w-[100vw]-- min-[921px]:w-[920px]-- w-[100vw] px-[10px]">
          <Link
            href={`/search?q=Hellmanns%20mayo`}
            data-testid={`hellmanns-mayo-banner`}
            className="block w-full relative aspect-[16/9]-- aspect-[920/516]"
          >
            <Image
              src={imagesArr[2]}
              alt="Hellmanns Mayo Banner"
              fill
              // sizes="(max-width: 920px) 100vw, (max-width: 1200px) 100%, 920px"
              sizes="100vw, 920px"
              className="object-cover"
            />
          </Link>
        </div>
      </div>

      <div className="flex xs:gap-x-1 xsp:gap-x-2">
        <div className="flex flex-col-- items-center justify-center xs:gap-y-0 xsp:gap-y-1 max-w-[500px]-- max-h-[1040px] w-full font-poppins">
          <div className="w-[50vw] pl-[10px] pr-[5px]">
            <Link
              href={`/search?q=kraft%20heinz%20canada%20ulc`}
              data-testid={`kraft-heinz-canada-banner`}
              className="block w-full relative aspect-[16/9]-- aspect-[500/516]"
            >
              <Image
                src={imagesArr[3]}
                alt={'Banner Image'}
                fill={true}
                quality={100}
                priority
                sizes="49vw, 300px"
                className="object-cover"
              />
            </Link>
          </div>

          <div className="w-[50vw] pr-[10px] pl-[5px]">
            <Link
              href={`/search?q=campbells`}
              data-testid={`campbells-banner`}
              className="block w-full relative aspect-[16/9]--  aspect-[500/516]"
            >
              <Image
                src={imagesArr[4]}
                alt={'Banner Image'}
                fill={true}
                quality={100}
                priority
                sizes="49vw, 200px"
                className="object-cover"
              />
            </Link>
          </div>
        </div>

        {/* <div className="flex items-center justify-center sm:justify-start max-w-[450px] w-full font-poppins">
          <div className="flex xs:min-w-[159px] xsp:min-w-[180px] sm:w-[330px] xs:min-h-[415px] xsp:min-h-[463px] sm:h-[860px] relative overflow-hidden">
            <Link
              href={`/search?q=kitch%2024/7%20spices`}
              data-testid={`kitch-spices-banner`}
            >
              <Image
                src={imagesArr[0]}
                alt={'Banner Image'}
                fill={true}
                quality={100}
                priority
                className="object-contain sm:object-cover--"
              />
            </Link>
          </div>
        </div> */}
      </div>

      <div className="flex-col items-center justify-center max-w-[920px]-- max-h-[516px]-- aspect-[920/516] w-auto h-auto w-full-- font-poppins">
        <div className="max-[920px]:w-[100vw]-- min-[921px]:w-[920px]-- w-[100vw] px-[10px]">
          <Link
            href={`/search?q=Kitch%2024/7%20disposables`}
            data-testid={`kitch-disposables-banner`}
            className="block w-full relative aspect-[16/9]-- aspect-[920/516]"
          >
            <Image
              src={imagesArr[1]}
              alt={'Banner Image'}
              fill={true}
              quality={100}
              priority
              // sizes="(max-width: 920px) 100vw, (max-width: 1200px) 100%, 920px"
              sizes="100vw, 920px"
              className="object-cover"
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
