import Image from '@components/ui/image'
import { useTranslation } from 'next-i18next'

export const BenefitsBar = () => {
  const { t: translate } = useTranslation('home')

  return (
    <div className="flex flex-wrap items-center justify-around mb-12 lg:mt-10 lg:mb-24 py-10 gap-y-8 min-h-[200px] bg-brand-darkblue text-white">
      <div className="flex items-center max-w-[370px] px-2 py-2-- w-full sm:w-1/2-- lg:w-1/4-- font-poppins">
        <div className="flex-shrink-0 mr-7">
          <div className="flex w-[50px] h-[30px] relative overflow-hidden">
            <Image
              src={'/assets/images/landing-one/quality-shipment-icon.svg'}
              alt={'Quality Shipment'}
              fill={true}
              quality={100}
              priority
            />
          </div>
        </div>
        <div>
          <h3 className="font-medium text-[20px]">
            {translate('quality-shipment')}
          </h3>
          <p className="font-normal text-[16px] mt-0.5 leading-tight  text-white">
            {translate('quality-shipment-subheading')}
          </p>
        </div>
      </div>

      <div className="flex items-center max-w-[370px] px-2 py-2-- w-full sm:w-1/2-- lg:w-1/4-- font-poppins">
        <div className="flex-shrink-0 mr-7">
          <div className="flex w-[50px] h-[38px] relative overflow-hidden">
            <Image
              src={'/assets/images/landing-one/on-demand-sourcing-icon.svg'}
              alt={'On Demand Sourcing Icon'}
              fill={true}
              quality={100}
              priority
            />
          </div>
        </div>
        <div>
          <h3 className="font-medium text-[20px]">
            {translate('on-demand-sourcing')}
          </h3>
          <p className="font-normal text-[16px] mt-0.5 leading-tight  text-white">
            {translate('on-demand-sourcing-subheading')}
          </p>
        </div>
      </div>

      <div className="flex items-center max-w-[370px] px-2 py-2-- w-full sm:w-1/2-- lg:w-1/4-- font-poppins">
        <div className="flex-shrink-0 mr-7">
          <div className="flex w-[46px] h-[50px] relative overflow-hidden">
            <Image
              src={'/assets/images/landing-one/shipping-savings-icon.svg'}
              alt={'Shipping Savings Icon'}
              fill={true}
              quality={100}
              priority
            />
          </div>
        </div>
        <div>
          <h3 className="font-medium text-[20px]">
            {translate('shipping-savings')}
          </h3>
          <p className="font-normal text-[16px] mt-0.5 leading-tight  text-white">
            {translate('shipping-savings-subheading')}
          </p>
        </div>
      </div>

      <div className="flex items-center max-w-[370px] px-2 py-2-- w-full sm:w-1/2-- lg:w-1/4-- font-poppins">
        <div className="flex-shrink-0 mr-7">
          <div className="flex w-[50px] h-[50px] relative overflow-hidden">
            <Image
              src={'/assets/images/landing-one/support-icon.svg'}
              alt={'Shipping Savings Icon'}
              fill={true}
              quality={100}
              priority
            />
          </div>
        </div>
        <div>
          <h3 className="font-medium text-[20px]">
            {translate('24-7-support')}
          </h3>
          <p className="font-normal text-[16px] mt-0.5 leading-tight -- text-white">
            {translate('24-7-support-subheading')}
          </p>
        </div>
      </div>
    </div>
  )
}
