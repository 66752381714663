import { QueryOptionsType, Product } from '@framework/types'
import { useQuery } from 'react-query'
import * as typesense from '../typesense'
import { useRouter } from 'next/router'

export const fetchFeaturedBrandProducts = async ({ queryKey }: any) => {
  const [_key, _params, locale] = queryKey
  const { page, perPage, brand } = _params

  const { hits, found }: any = await typesense.getFeaturedBrandProducts(
    locale,
    {
      page,
      perPage,
      brand,
    },
  )

  const products: Product[] = []

  hits.map((h: any) => {
    if (h.document.name && h.document.description) {
      products.push(h.document)
    }
  })

  return { products, found }
}
export const useFeaturedBrandProductsQuery = (options: QueryOptionsType) => {
  return useQuery<{ products: Product[]; found: any }, Error>(
    ['hp_brand_sku', options, useRouter().locale],
    fetchFeaturedBrandProducts,
  )
}
