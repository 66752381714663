import ProductCardNew from '@components/product/product-cards/product-card-new'
import { useFeaturedProductsQuery } from '@framework/product/get-all-featured-products'
import { LIMITS } from '@framework/utils/limits'
import { useState } from 'react'
import Image from 'next/image'
import Link from '@components/ui/link'
import { featuredCategories } from '@utils/main-categories'
import { useTranslation } from 'next-i18next'

export const FeaturedCategories = () => {
  const { t: translate } = useTranslation('home')

  const {
    data: products,
    isLoading: isProductsLoading,
    error: productsError,
  } = useFeaturedProductsQuery({
    limit: LIMITS.BEST_SELLER_PRODUCTS_LIMITS,
  })

  return (
    <>
      <div className="lg:!px-[18px]">
        <h1 className="text-[29px] md:text-[46px] font-medium text-gray-900 !mb-6 text-center">
          {translate('featured-categories')}
        </h1>
        <h2 className="text-[#8A8A8A] !mb-10 text-center max-w-2xl mx-auto font-['Poppins'] text-[16px] font-normal px-3">
          {translate('featured-categories-subheading')}
        </h2>

        <div className="flex-- flex-wrap justify-center zoom-responsive-- grid items-baseline justify-items-center grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6-- 4xl:grid-cols-7-- gap-3 md:gap-x-4 2xl:gap-5 space-x-2 lg:space-x-4 mb-24 -mt-14-- gap-y-12">
          {featuredCategories.data.map((category, index) => (
            <Link
              key={index}
              href={`/${category.slug}`}
              data-testid={`${category.slug.replace(
                '.html',
                '',
              )}-category-link`}
              className="opacity-80 inline-flex text-xs uppercase font-bold ltr:pl-1.5-- rtl:pr-1.5-- items-center relative transition-all hover:opacity-100"
            >
              <div className="flex flex-col items-center justify-center sm:justify-start w-[348px]-- font-poppins">
                <div className="flex items-center justify-center min-w-[100px] sm:min-w-[200px] min-h-[100px] sm:min-h-[200px] sm:w-auto sm:h-auto relative overflow-hidden rounded-[100%] bg-[#F0F0F0]">
                  <Image
                    src={category.image.original}
                    alt={'Banner Image'}
                    fill={true}
                    quality={100}
                    priority
                    className="object-contain sm:object-cover-- p-[15px] sm:p-[37px]"
                  />
                </div>

                <div className="flex justify-center mt-4 sm:mt-8 relative z-40">
                  <button
                    key={index}
                    // onClick={() => setActiveCategory(category.name)}
                    className={`category-button w-[207px]-- h-[56px]-- px-4 py-2 text-[16px] sm:text-[20px] max-w-[120px] sm:max-w-max rounded-md font-medium font-['Poppins'] transition-shadow duration-200 text-[#000] leading-8`}
                  >
                    {/* {category.name} */}
                    {translate(
                      `categories:${category.name.toLocaleLowerCase()}`,
                    )}
                  </button>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}
