import { Swiper, SwiperSlide } from '@components/ui/carousel/slider'
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper'
import { StarRating } from './star-rating'
import { useTranslation } from 'next-i18next'

import 'swiper/css/navigation'
import 'swiper/css/pagination'

export const CustomerReviewsCarousel = () => {
  const { t: translate } = useTranslation('home')

  const customerReviews = [
    {
      review:
        'Thank you so much, everything was packaged carefully and arrived in great time. Shoutout to the ups driver too!',
      rating: 5,
      name: 'Katie C.',
      designation: 'Top Customer',
    },
    {
      review:
        'I am so glad I found this site. I look forward to receiving my shipment!',
      rating: 5,
      name: 'Lorraine S.',
      designation: 'Top Customer',
    },
    {
      review:
        'Great gluten free and sulfite free hard to find this kind of product anywhere. And it tastes great.',
      rating: 5,
      name: 'Robert S.',
      designation: 'Top Customer',
    },
    {
      review:
        'Easy to order and reasonable priced and optional payment methods.',
      rating: 5,
      name: 'Robert B.',
      designation: 'Top Customer',
    },
    {
      review:
        'Online ordering was simple and easy. Product shipped within two days and arrived on time by UPS.',
      rating: 5,
      name: 'Albert B.',
      designation: 'Top Customer',
    },
  ]

  const CustomerReviewCard = ({ reviewItem }) => {
    return (
      <div className="flex justify-center w-[658px] h-[400px]-- px-16 2xl:px-0 py-12 md:py-20 rounded-[10px] shadow-[0px_20px_60px_0px_#2E213D14] bg-white zoom-responsive-- zoom-42 xsp:zoom-50 sm:!zoom-100">
        <div className="flex justify-center flex-col w-[419px]">
          <blockquote className="font-[16px] font-poppins font-[#484848] !mb-6">
            &quot;{reviewItem.review}&quot;
          </blockquote>

          <div className="flex mb-8">
            <StarRating rating={reviewItem.rating} />
          </div>

          <div className="flex mb-3">
            <hr className="w-[230px] border-[#484848]" />
          </div>

          <div className="flex mb-3">
            <small className="text-[32px] font-normal">{reviewItem.name}</small>
          </div>

          <div className="flex">
            <span className="font-normal text-[16px] font-poppins">
              {reviewItem.designation}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="customer-reviews-carousel px-4 lg:!px-[18px] mb-4 lg:mb-32 hidden-- sm:block--">
        <h1 className="text-[29px] md:text-[46px] font-medium text-gray-900 !mb-6 text-center">
          {translate('customer-reviews')}
        </h1>
        <h2 className="text-[#8A8A8A] lg:!mb-10-- text-center max-w-2xl mx-auto font-['Poppins'] text-[16px] font-normal">
          {translate('customer-reviews-subheading')}
        </h2>

        <div className="flex mx-auto w-[1296px]-- relative-- left-[-110px]--">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            // slidesPerView={'auto'}
            centeredSlides={true}
            loop={true}
            slidesPerView={1}
            breakpoints={{
              320: {
                slidesPerView: 1,
                // spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                // spaceBetween: 50,
              },
            }}
            // autoplay={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            // cssMode={true}
            // pagination={true}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            keyboard={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 175,
              depth: 10,
              scale: 0.75,
              modifier: 1,
              slideShadows: false,
            }}
            modules={[Autoplay, Navigation, Pagination, EffectCoverflow]}
            className="mySwiper"
          >
            {customerReviews.map((item, index) => (
              <SwiperSlide key={index}>
                <CustomerReviewCard reviewItem={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}
