import Image from 'next/image'
import Link from '@components/ui/link'
import ProductCardNew from '@components/product/product-cards/product-card-new'

import { LIMITS } from '@framework/utils/limits'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { useFeaturedBrandProductsQuery } from '@framework/product/get-featured-brand-products'

export const BrandHighlight = ({ locale }) => {
  const { t: translate } = useTranslation('home')

  const homeAssetsPath = '/assets/images/home-one'

  const brandBannersEN = [
    `${homeAssetsPath}/1-kitch-md-hp.png`,
    `${homeAssetsPath}/2-kitch-md-hp.png`,
    `${homeAssetsPath}/1-kitch-full-hp.png`,
    `${homeAssetsPath}/2-kitch-full-hp.png`,
  ]

  const brandBannersFR = [
    `${homeAssetsPath}/1-kitch-md-hp.png`,
    `${homeAssetsPath}/2-kitch-md-hp.png`,
    `${homeAssetsPath}/1-kitch-full-hp.png`,
    `${homeAssetsPath}/2-kitch-full-hp.png`,
  ]

  const brandBanners = locale === 'en-CA' ? brandBannersEN : brandBannersFR

  const {
    data: products,
    isLoading: isProductsLoading,
    error: productsError,
  } = useFeaturedBrandProductsQuery({
    limit: LIMITS.BEST_SELLER_PRODUCTS_LIMITS,
  })

  return (
    <>
      <div className="flex mt-8 mb-0 md:mb-4 lg:mt-24 !px-[18px]">
        <h2 className="text-center text-[25px] md:text-[40px] font-medium">
          {translate('brand-highlight')}
        </h2>
      </div>

      <div className="flex flex-col items-center justify-center lgp:flex-row lgp:items-start">
        <Link
          href={`/search?q=browne%20chafer`}
          data-testid={`browne-chafer-banner`}
          className="opacity-80-- inline-flex text-xs uppercase font-bold items-center relative transition-all hover:opacity-100 py-2 gap-y-3"
        >
          <div className="flex flex-col items-center justify-center sm:justify-start max-w-[614px] w-full font-poppins xl:mr-4 4xl:mr-4">
            <div className="flex xl:w-[483px] xl:h-[342px] 4xl:hidden aspect-[16/9] relative overflow-hidden">
              <Image
                src={brandBanners[0]}
                alt={'Banner Image'}
                fill={true}
                quality={100}
                priority
                className="object-contain"
              />
            </div>

            <div className="flex xl:hidden 4xl:flex 4xl:w-[614px] 4xl:h-[342px] aspect-[16/9] relative overflow-hidden">
              <Image
                src={brandBanners[2]}
                alt={'Banner Image'}
                fill={true}
                quality={100}
                priority
                className="object-contain"
              />
            </div>
          </div>
        </Link>

        {/* 3 Products till 1xl */}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4 2xl:gap-5 justify-center 4xl:justify-normal clear-both py-2 gap-y-3 flex-auto 2xl:hidden">
          {products?.products &&
            products.products?.slice(0, 3)?.map((product: any, index) => (
              <div
                key={`${'knorr-products'}-${index}`}
                data-testid={`product-${product.id}`}
                className={`relative borobazar-alpine-product-card rounded-[10px] bg-white-- transition-all hover:shadow-product-hover shadow-bestSeller bg-white`}
              >
                <ProductCardNew product={product} />
              </div>
            ))}
        </div>

        {/* 4 Products from 2xl */}
        <div className="hidden grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 md:gap-4 2xl:gap-5 justify-center 4xl:justify-normal clear-both py-2 gap-y-3 flex-auto 2xl:grid">
          {products?.products &&
            products.products?.slice(0, 4)?.map((product: any, index) => (
              <div
                key={`${'knorr-products'}-${index}`}
                data-testid={`product-${product.id}`}
                className={`relative borobazar-alpine-product-card rounded-[10px] bg-white-- transition-all hover:shadow-product-hover shadow-bestSeller bg-white`}
              >
                <ProductCardNew product={product} />
              </div>
            ))}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center lgp:flex-row lgp:items-start">
        <Link
          href={`/search?q=browne%20chafer`}
          data-testid={`browne-chafer-banner`}
          className="opacity-80-- inline-flex text-xs uppercase font-bold items-center relative transition-all hover:opacity-100 py-2 gap-y-3"
        >
          <div className="flex flex-col items-center justify-center sm:justify-start max-w-[614px] w-full font-poppins xl:mr-4 4xl:mr-4">
            <div className="flex xl:w-[483px] xl:h-[342px] 4xl:hidden aspect-[16/9] relative overflow-hidden">
              <Image
                src={brandBanners[1]}
                alt={'Banner Image'}
                fill={true}
                quality={100}
                priority
                className="object-contain"
              />
            </div>

            <div className="flex xl:hidden 4xl:flex 4xl:w-[614px] 4xl:h-[342px] aspect-[16/9] relative overflow-hidden">
              <Image
                src={brandBanners[3]}
                alt={'Banner Image'}
                fill={true}
                quality={100}
                priority
                className="object-contain"
              />
            </div>
          </div>
        </Link>

        {/* 3 Products till 1xl */}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4 2xl:gap-5 justify-center 4xl:justify-normal clear-both py-2 gap-y-3 flex-auto 2xl:hidden">
          {products?.products &&
            products.products?.slice(4, 7)?.map((product: any, index) => (
              <div
                key={`${'knorr-products'}-${index}`}
                data-testid={`product-${product.id}`}
                className={`relative borobazar-alpine-product-card rounded-[10px] bg-white-- transition-all hover:shadow-product-hover shadow-bestSeller bg-white`}
              >
                <ProductCardNew product={product} />
              </div>
            ))}
        </div>

        {/* 4 Products from 2xl */}
        <div className="hidden grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 md:gap-4 2xl:gap-5 justify-center 4xl:justify-normal clear-both py-2 gap-y-3 flex-auto 2xl:grid">
          {products?.products &&
            products.products?.slice(4, 8)?.map((product: any, index) => (
              <div
                key={`${'knorr-products'}-${index}`}
                data-testid={`product-${product.id}`}
                className={`relative borobazar-alpine-product-card rounded-[10px] bg-white-- transition-all hover:shadow-product-hover shadow-bestSeller bg-white`}
              >
                <ProductCardNew product={product} />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
