import ProductCardNew from '@components/product/product-cards/product-card-new'
import { useFeaturedProductsQuery } from '@framework/product/get-all-featured-products'
import { useTrendingProductsQuery } from '@framework/product/get-all-trending-products'
import { LIMITS } from '@framework/utils/limits'
import { useEffect, useLayoutEffect, useState } from 'react'
import { i18n, useTranslation } from 'next-i18next'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { ROUTES } from '@utils/routes'

export const DirectDeals = ({ locale }) => {
  const { t: translate } = useTranslation('home')

  const router = useRouter()

  const [activeCategory, setActiveCategory] = useState('trending-now')
  const [maxFeaturedProductsToShow, setMaxFeaturedProductsToShow] = useState(12)

  const categories = ['trending-now', 'featured-products']

  const subHeadingMap = {
    'trending-now': 'trending-now-subheading',
    'featured-products': 'featured-products-subheading',
  }

  // useEffect(() => {
  //   setActiveCategory(translate('trending-now'))
  // }, [locale])

  const {
    data: featuredProducts,
    isLoading: isFeaturedProductsLoading,
    error: featuredProductsError,
  } = useFeaturedProductsQuery({
    // limit: LIMITS.BEST_SELLER_PRODUCTS_LIMITS,
  })

  const {
    data: trendingProducts,
    isLoading: isTrendingProductsLoading,
    error: trendingProductsError,
  } = useTrendingProductsQuery({
    // limit: LIMITS.BEST_SELLER_PRODUCTS_LIMITS,
  })

  const productsMap = {
    'trending-now': trendingProducts,
    'featured-products': featuredProducts,
  }

  const productsToShow = productsMap[activeCategory]

  // Effect to update translations when language changes
  useEffect(() => {
    i18n.reloadResources(i18n.language, ['home'])
  }, [i18n.language])

  return (
    <>
      <div className="lg:!px-[18px] px-2 md:px-0">
        <h1 className="text-[28px] md:text-[46px] font-medium text-gray-900 !mb-6 px-3 text-center">
          {translate('direct-deals-title')}
        </h1>
        <h2 className="text-[#8A8A8A] !mb-10 px-3 text-center max-w-3xl mx-auto font-['Poppins'] text-[16px] font-normal">
          {translate(subHeadingMap[activeCategory])}
        </h2>

        <div className="flex flex-row flex-wrap lg:gap-2 gap-y-4 items-center justify-center space-x-4 mb-12 zoom-responsive--">
          {categories.map((category, index) => (
            <button
              key={index}
              data-testid={`${category}-category-switch-button`}
              onClick={() => setActiveCategory(category)}
              className={`category-button min-w-[126px] md:min-w-[207px] h-[56px]-- px-3 md:px-6 lg:px-12 py-4 lg:py-5 !mx-4 text-[15px] md:text-[16px] rounded-md font-normal font-['Poppins'] leading-4 ${
                category === activeCategory
                  ? 'bg-[#293e6d] text-white'
                  : 'bg-[#f8f9fa] text-[#8A8A8A]'
              } shadow-sm transition-shadow duration-200 hover:shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)]`}
            >
              {translate(category)}
            </button>
          ))}
        </div>

        {/* <div className="flex flex-wrap items-center justify-center 2xl:justify-normal clear-both">
          {productsToShow &&
            productsToShow.map((product, index) => (
              <div
                key={`featured-category-products-${index}`}
                className={`product-card-new-slide w-[376px] min-w-[348px] max-w-[500px] px-1.5 md:px-2 xl:px-2.5 py-[10px] gap-y-0`}
                data-testid={`product-${product.id}`}
                // style={{ width: '348px' }}
              >
                <ProductCardNew product={product} />
              </div>
            ))}
        </div> */}

        {/*********************************/}
        <div className="gs-products-block gs-block-spacing-wrapper">
          <div className="gs-product-grid-main-wrap gs-grid-item-wrap gs-maple-grid-wrap grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 1xl:grid-cols-5 2xl:grid-cols-6 4xl:grid-cols-6 gap-3 md:gap-4 2xl:gap-5">
            {/***** LOOP *****/}
            {productsToShow &&
              [...productsToShow, ...productsToShow]
                .slice(0, maxFeaturedProductsToShow)
                .map((product, index) => (
                  // <div
                  //   key={`featured-category-products-${index}`}
                  //   className={`product-card-new-slide w-[376px] min-w-[348px] max-w-[500px] px-1.5 md:px-2 xl:px-2.5 py-[10px] gap-y-0`}
                  //   data-testid={`product-${product.id}`}
                  //   // style={{ width: '348px' }}
                  // >
                  //   <ProductCardNew product={product} />
                  // </div>

                  <div
                    key={index}
                    className="relative borobazar-alpine-product-card rounded-[10px] bg-white-- transition-all hover:shadow-product-hover shadow-bestSeller bg-white"
                  >
                    <ProductCardNew product={product} />
                  </div>
                ))}
            {/***** // LOOP *****/}
          </div>
        </div>
        {/*********************************/}

        <div className="mt-12 flex justify-center">
          {/* <button
            data-testid={`view-more-category-items-button`}
            onClick={() => {
              // router.push(ROUTES.CATEGORY)
            }}
            className={`category-button font-poppins min-w-[126px] md:min-w-[207px] h-[56px]-- px-6 lg:px-12 py-4 lg:py-5 !mx-4 rounded-md text-[14px] md:text-[16px] font-normal leading-4 shadow-sm transition-shadow duration-200 bg-[#293e6d] text-white`}
          >
            {translate('view-more-text')}
          </button> */}
        </div>
      </div>
    </>
  )
}

// export const getStaticProps: GetStaticProps = async ({ locale }) => {
//   return {
//     props: {
//       locale,
//       // dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
//       ...(await serverSideTranslations(locale!, [
//         'categories',
//         'home',
//         'common',
//         'forms',
//         'menu',
//         'footer',
//       ])),
//     },
//     revalidate: 60,
//   }
// }
