import Link from '@components/ui/link'
import Image from 'next/image'

export const TopBanners = ({ images }) => {
  const imagesArr = images && images.length > 0 ? images : []

  return (
    <div className="flex flex-col sm:flex-row justify-center items-center gap-2 mx-auto mt-5 bg-[#fff]">
      <div className="flex items-center justify-center sm:justify-start max-w-[400px] w-full font-poppins">
        <div className="flex min-w-[350px] sm:w-[400px] h-[850px] sm:h-[1040px] relative overflow-hidden">
          <Link
            href={`/search?q=kitch%2024/7%20spices`}
            data-testid={`kitch-spices-banner`}
          >
            <Image
              src={imagesArr[0]}
              alt={'Banner Image'}
              fill={true}
              quality={100}
              priority
              className="object-contain sm:object-cover"
            />
          </Link>
        </div>
      </div>

      <div className="flex-col items-center justify-center max-w-[920px] max-h-[1040px] w-full font-poppins">
        <div className="flex min-w-[340px] sm:w-[920px] h-[187px] sm:h-[516px] relative overflow-hidden mb-2 mx-auto">
          <Link
            href={`/search?q=Kitch%2024/7%20disposables`}
            data-testid={`kitch-disposables-banner`}
          >
            <Image
              src={imagesArr[1]}
              alt={'Banner Image'}
              fill={true}
              quality={100}
              priority
              className="object-contain sm:object-cover"
            />
          </Link>
        </div>

        <div className="flex min-w-[340px] sm:w-[920px] h-[193px] sm:h-[516px] relative overflow-hidden mx-auto">
          <Link
            href={`/search?q=Hellmanns%20mayo`}
            data-testid={`hellmanns-mayo-banner`}
          >
            <Image
              src={imagesArr[2]}
              alt={'Banner Image'}
              fill={true}
              quality={100}
              priority
              className="object-contain sm:object-cover"
            />
          </Link>
        </div>
      </div>

      <div className="flex-col items-center justify-center max-w-[500px] max-h-[1040px] w-full font-poppins">
        <div className="flex min-w-[340px] sm:w-[500px] h-[375px] sm:h-[516px] relative overflow-hidden mb-2 mx-auto">
          <Link
            href={`/search?q=kraft%20heinz%20canada%20ulc`}
            data-testid={`kraft-heinz-canada-banner`}
          >
            <Image
              src={imagesArr[3]}
              alt={'Banner Image'}
              fill={true}
              quality={100}
              priority
              className="object-contain sm:object-cover"
            />
          </Link>
        </div>

        <div className="flex min-w-[340px] sm:w-[500px] h-[375px] sm:h-[516px] relative overflow-hidden mx-auto">
          <Link href={`/search?q=campbells`} data-testid={`campbells-banner`}>
            <Image
              src={imagesArr[4]}
              alt={'Banner Image'}
              fill={true}
              quality={100}
              priority
              className="object-contain sm:object-cover"
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
